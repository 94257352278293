<template>
  <div>
    <div class="container px-5">
      <div class="row gx-5 align-items-center text-center pt-4 pb-4" style="justify-content: center;">

        <!-- 필터 or 검색 DOM -->
        <!-- <div class="card order-card mb-2 order-shadow" style="width: 40rem;">
          <b-dropdown style="width: 20px;" text="전체" variant="outline-dark" class="m-2">
            <b-dropdown-item href="#">전체</b-dropdown-item>
            <b-dropdown-item href="#">결제완료</b-dropdown-item>
            <b-dropdown-item href="#">결제취소</b-dropdown-item>
          </b-dropdown>
        </div> -->

        <template v-if="orders.length >= 1">
          <div v-for="(order, index) in orders" :key="index" class="card order-card mb-2 order-shadow" style="width: 40rem;">
            <div class="" style="display: flex; justify-content: space-between;">
              <div class="text-left">
                  <h5><b>{{ payStatEnum(order) }}</b></h5>
              </div>
              <div class="text-right">
                <div @click="deleteOrder(index)" style="cursor: pointer;">
                  <img src="../../assets/svg/icon-cross.svg" alt="주문삭제" style="width: 12px; height: 12px;">
                </div>
              </div>
            </div>
  
            <div style="display: flex; align-items: center; text-align: left;">
              <img class="card-img-top" src="../../assets/svg/cube.svg" alt="..." style=" width: 75px; height: 75px; border-radius: 4px;" />
              <div class="ml-3" style="display: flex; flex-direction: column;">
                <div>
                  <span style="color: #aaaaac;">{{ momentDate(order.createdAt) }}</span>
                </div>
                <div>
                  <h5 class="mb-0">
                    {{ order.products[0].disp_name }}
                    <span v-if="order.products.length > 1" v-b-tooltip.hover.html="order.productsName">, 외 {{ order.products.length - 1 }}건</span>
                  </h5>
                </div>
                <div>
                  <span>{{ commify(order.amount) }}원</span>
                </div>
                <div>
                  <div v-if="order.payStat === '99' && order.cancelYn === 'N'">
                    <a class="fc-howcost noto-sans" :href="orderDetailUrl(index)" target="_blank">
                      결제진행>
                    </a>
                  </div>
                  <div v-if="order.payStat === '00' && order.cancelYn === 'N'">
                    <a class="fc-howcost noto-sans" :href="orderDetailUrl(index)" target="_blank">
                      주문상세>
                    </a>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="card order-card mb-2 order-shadow" style="width: 40rem;">
            <div>
              <div class="text-left text-center">
                  <h5 class="mb-0"><b style="color: darkgrey;">== 결제 내역이 없습니다 ==</b></h5>
                  <a href="/#/store">
                    <img class="promotion-banner" src="../../../public/images/product_order_banner.png" alt="...">
                  </a>
              </div>
            </div>
          </div>
        </template>

        <div class="card order-card mb-2 order-shadow" style="width: 40rem;">
          <b-pagination
            class="mb-0"
            v-model="currentPage" 
            :per-page="perPage" 
            :total-rows="rows"
            hide-ellipsis
            align="fill"
            prev-text="<"
            next-text=">"
            first-text="|◀"
            last-text="▶|"
          >
          </b-pagination>
        </div>

        <div class="card promotion-card mb-2 mt-3" style="width: 40rem;">
          <div class="mb-1 ml-1">
            <h5><b>더 빠른 적산을 위해 추천해요!</b></h5>
          </div>
          <ul class="pl-1 mb-0 w-scroll">

            <li v-for="(info, index) in promotionInfo" :key="index" class="mr-3" style="list-style: none; display: inline-block; width: 100px;">
              <a class="bf-noto" style="text-decoration: none;" href="/#/store">
                <div>
                  <img class="card-img-top" :src="$imgUrl + info.sale_file_nm" alt="..." style=" width: 95px; height: 95px; border-radius: 2px;" />
                </div>
                <div class="promotion-title" style="white-space: initial;" :title="info.disp_name">
                  {{ info.disp_name }}
                </div>
                <div class="mt-1" style="margin-top: 1px;">
                  {{ commify(info.unit_price) }}
                </div>
              </a>
            </li>

          </ul>
        </div>

        <div class="card promotion-card mb-2 mt-1" style="width: 40rem;">
          <div class="mb-1 ml-1">
            <h5><b>적산 업체 찾고계신가요?</b></h5>
          </div>
          <div>
            <img class="promotion-banner" src="../../../public/images/promotion_1.png" alt="...">
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
  
<script>
  import {
      alertModal,
      apiCall,
      cloneVar,
      confirmModal,
      checkBisNo,
      alertConfirm,
      alertSync,
      alertWarn,
      alertSuccess,
      apiMultiPart,
      commify,
      momentDate,
  } from '../../common/utils';
  import { PayStatOpts, PayTypeOpts } from '../../common/salesType';

  export default {
    name: "OrderList",
    data() {
      return {
        orders: [],
        promotionInfo: [],

        rows: 100, // 전체 row 개수
        perPage: 10, // 페이지당 개수
        currentPage: 1, // defualt select page
      };
    },
    watch: {
      currentPage: "ordersPaging",
    },
    computed: {
    },
    async created() {
      this.ordersPaging();
      this.promotionProductInfo();
    },
    methods: {
      commify(num) { return commify(num) },
      momentDate(date) { return momentDate(date) },
      
      // payStatOpts: {'99': '미결제', '10': '부분결제', '20': '초과결제', '00': '결제완료', '09':'결제취소', '19':'부분결제취소', '29':'초과결제취소'},
      payStatEnum(order) {
        // 분기 순서 중요!
        if (order.payStat === '09') return "결제취소";
        if (order.cancelYn === 'Y') return "주문취소";
        if (order.payStat === '99') return "결제대기";
        return PayStatOpts[order.payStat];
      },

      async ordersPaging() {
        const param = {
          perPage: this.perPage,
          currentPage: this.currentPage,
        };

        const orderList = await apiCall('POST', `/api/payment/order-list`, param);
        
        this.orders = orderList.result.result;
        this.rows = orderList.result.rows;
      },

      async promotionProductInfo() {
        const r = await apiCall('GET', `/api/product/promotion`);
        // console.log("promotionProductInfo ===========> ", r);
        if (r.code === 200) {
          this.promotionInfo = r.result;
        } else {
          console.error(r.message);
        }
      },

      async deleteOrder(index) {
        const selectedOrder = this.orders[index];
        
        const payType = selectedOrder.payType;
        console.log( "payType : ", payType );

        if (payType !== '0000' && payType !== '9999') {
          alert("결제가 진행된 주문은 삭제하실 수 없습니다.");
          return ;
        }

        if (confirm("삭제된 내역은 복구할 수 없습니다. 정말로 삭제하시겠습니까?")) {
          const orderParam = {
            orderToken: selectedOrder.orderToken,
            orders: selectedOrder.orders
          }

          const r = await apiCall('DELETE', `/api/order/delete`, orderParam);
          if (r.code === 200) {
            alert("주문 내역이 삭제되었습니다.");
            window.location.reload();
          } else {
            alert("주문 내역 삭제를 실패했습니다. 다시 시도해주세요.");
            window.location.reload();
          }
        }
        return ;
      },

      orderDetailUrl(idx) {
        let url = $webUrl;
        const order = this.orders[idx];
        // if (order.type === 'PACKAGE') {
        //   url += '/#/subscription/package-order/detail/' + order.orderToken;
        // } else {
        //   url += '/#/subscription/multi-order/detail/' + order.orderToken;
        // }
        url += '/#/subscription/multi-order/detail/' + order.orderToken;
        return url;
      }

    }
  };
</script>
  
